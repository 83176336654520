import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import LiquorPage from '../../components/location-specific/LiquorPage'

class LiquorBryant extends React.Component
{
	render()
	{
		const location = "bryant";
		const currentPage = "liquor";
		const title = "Liquor";

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<LiquorPage location={location} />
			</Layout>
		)
	}
}

export default LiquorBryant