import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
// import MenuTable from '../MenuTable'
// import { formatDatoCMSData } from '../../services/datoCMS.service'
// import { locationHasMenuItem } from '../../services/location.service'

export default function LiquorPage(props) {
  /* Currently we don't have any liquor
  const query = useStaticQuery(graphql`
  query liquorQuery {
    datoCmsNotCoffeeMenu {
      notCoffeeMenu {
        id
      }
    }
  }`);

  const liquorData = formatDatoCMSData(query);
  const happyHourData = formatDatoCMSData(query.datoCmsHappyHour);
  */
  // let liquorData = {};

  // Remove once we have liquor again
  return (
    <div id="liquormenu" className="wrapper style1">
      <div className="container">
        <Menu>
          <header className="major">
            <h2>Liquor</h2>
          </header>
          <div className="box alt">
            <p className="centered">Temporarily Unavailable</p>
            <p className="centered">We apologize for the inconvenience.</p>
          </div>
        </Menu>
      </div>
    </div>
	)

	/*

  // We want to combine our cocktails into 1 view for now. Leave this here instead of in the menu because it may change back in the future
  let cocktails = [];
  for (let key in liquorData.cocktails.signatures) {
    cocktails.push(liquorData.cocktails.signatures[key]);
  }
  for (let key in liquorData.cocktails.brunch) {
    cocktails.push(liquorData.cocktails.brunch[key]);
  }
  for (let key in liquorData.cocktails.classics) {
    cocktails.push(liquorData.cocktails.classics[key]);
  }

  const happyHour = (locationHasMenuItem(props.location, liquorData.happyHour) ?
    <React.Fragment>
      <div className="box alt">
        <div className="row">
          <div className="col-12 col-12-xsmall">
            <h3 className="centered">Happy Hour</h3>
            <p className="centered">
              ${liquorData.happyHour.discount} off Wine &amp; Beer!<br />
              {liquorData.happyHour.hours}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
    : null);

  return (
    <div id="liquormenu" className="wrapper style1">
      {/* <span className="image fit main bottom">
              <img src={backgroundImage} alt="" />
            </span> * /}
      <div className="container">
        <Menu>
          <header className="major">
            <h2>Liquor</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-7 col-12-xsmall">
                <h3 className="centered">Cocktails</h3>
                <MenuTable data={cocktails} location={props.location} />
              </div>
              <div className="col-5 col-12-xsmall">
                <h3 className="centered">Whiskey</h3>
                <MenuTable data={liquorData.whiskey} location={props.location} />
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-12-xsmall">
                <h3 className="centered">Cognac</h3>
                <MenuTable data={liquorData.cognac} location={props.location} />
                <h3 className="centered">Rum</h3>
                <MenuTable data={liquorData.rum} location={props.location} />
              </div>
              <div className="col-4 col-12-xsmall">
                <h3 className="centered">Gin</h3>
                <MenuTable data={liquorData.gin} location={props.location} />
                <h3 className="centered">Vodka</h3>
                <MenuTable data={liquorData.vodka} location={props.location} />
              </div>
              <div className="col-4 col-12-xsmall">
                <h3 className="centered">Tequila</h3>
                <MenuTable data={liquorData.tequila} location={props.location} />
              </div>
            </div>
            {/* <div className="row">
                <div className="col-6 col-12-xsmall">
                  <h3 className="centered">Seasonal Cocktails</h3>
                  <p className="small centered">Available 5 PM - Close</p>
                  <MenuTable data={liquorData.cocktails.seasonal} location={props.location} />
                </div>
              </div> * /}
          </div>
          {happyHour}
        </Menu>
        <p className="centered">At Ellis Island, we strive to support locally owned businesses &amp; distributors to provide you with the best products possible.
          <br />Our wines are sources from family-owned vineyards, of which many are organic &amp; sustainably grown.</p>
      </div>
    </div >
		)
	*/
}
